<template>
  <div class="fs-container">
    <div v-if="loyaltyBlogDetail" class="fs-section fs-article">
      <div class="banner-box">
        <div class="fs-pic banner-pic">
          <img
            :src="loyaltyBlogDetail.BannerImage"
            :alt="loyaltyBlogDetail.Title"
          />
        </div>
        <div v-if="loyaltyBlogDetail.days == 0" class="banner-info">
          <h1>{{ loyaltyBlogDetail.Title }}</h1>
          <p>
            <strong
              ><span class="color-red">{{ loyaltyBlogDetail.Tagging }}</span>
              NEWS {{ loyaltyBlogDetail.MonthString }}</strong
            >
            {{ loyaltyBlogDetail.CreatedOn }}
          </p>
        </div>
        <div v-if="loyaltyBlogDetail.days == 1" class="banner-info">
          <h1>{{ loyaltyBlogDetail.Title }}</h1>
          <p>
            <strong
              ><span class="color-red">{{ loyaltyBlogDetail.Tagging }}</span>
              NEWS {{ loyaltyBlogDetail.MonthString }}</strong
            >
            {{ loyaltyBlogDetail.CreatedOn }} - 1 DAYS AGO
          </p>
        </div>
        <div v-if="loyaltyBlogDetail.days > 1" class="banner-info">
          <h1>{{ loyaltyBlogDetail.Title }}</h1>
          <p>
            <strong
              ><span class="color-red">{{ loyaltyBlogDetail.Tagging }}</span>
              NEWS {{ loyaltyBlogDetail.MonthString }}</strong
            >
            {{ formatDate(loyaltyBlogDetail.CreatedOn) }} -
            {{ loyaltyBlogDetail.days }} DAYS AGO
          </p>
        </div>
      </div>

      <div class="fs-inr article-inr">
        <div class="acticle-detail">
          <div class="acticle-top">
            <div class="user-box">
              <div class="user-pic">
                <img
                  :src="loyaltyBlogDetail.AuthorIcon"
                  :alt="loyaltyBlogDetail.Author"
                />
              </div>
              <div class="user-txt">{{ loyaltyBlogDetail.Author }}</div>
            </div>
            <div class="social">
              <div class="social-inr">
                <div
                  class="fb-like"
                  data-width=""
                  data-layout="button"
                  data-action="like"
                  data-size="large"
                  data-share="true"
                ></div>
              </div>
            </div>
          </div>

          <div class="article-content">
            <img
              v-if="loyaltyBlogDetail.DetailImage"
              :src="loyaltyBlogDetail.DetailImage"
              :alt="loyaltyBlogDetail.Title"
            />
            <div class="detail-wrap" v-html="loyaltyBlogDetail.Detail"></div>
          </div>
          <div class="social-box">
            <div class="social-inr">
              <div
                class="fb-like"
                data-layout="button"
                data-action="like"
                data-size="large"
                data-share="true"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
export default {
  name: "ClubBlogDetail",
  data() {
    return {
      loyaltyBlogDetail: null,
    };
  },
  methods: {
    getLoyaltyBlogDetail(slug) {
      this.loading(true);
      useJwt
        .post("club/get-blogLoyalty-by-slug", {
          columnFilters: {
            Slug: slug,
          },
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        })
        .then((res) => {
          this.loading(false);
          this.loyaltyBlogDetail = res.data.result[0];
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },

  mounted() {
    this.getLoyaltyBlogDetail(this.$route.params.Slug);
  },
};
</script>

<style>
</style>